
import Vue from 'vue'
import App from './App.vue'
import AppB from './AppB.vue'
import AppC from './AppC.vue'
import router from './router'
import routerB from './router/routerB'
import routerC from './router/routerC'
import i18n from "./i18n.js"
import Antd from 'ant-design-vue';
import '@/assets/js/http'
import 'ant-design-vue/dist/antd.css'
import store from './store';

Vue.use(Antd);

const globalData = Vue.observable({
  lang: 'en', // 默认语言为英文
});

Vue.prototype.$global = globalData; // 将可响应对象存储在全局变量中
Vue.config.productionTip = false

if (localStorage.lang !== undefined) {
  i18n.locale =  localStorage.getItem('lang')
} else {
  localStorage.setItem("lang", "en-US")
  i18n.locale = localStorage.getItem('lang')
}

const initVueInstance = (router) => {
  new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
    created() {
      const domain = window.location.hostname;
      if (domain.includes('pasarkartu.com')) {
        this.$root.$options.render = h => h(AppB);
      } else if (domain.includes('kukucard.com')) {
        this.$root.$options.render = h => h(AppC);
      } else {
        this.$root.$options.render = h => h(App);
      }
    }
  }).$mount('#app')
}

const domain = window.location.hostname;
if (domain.includes('pasarkartu.com')) {
  initVueInstance(routerB);
} else if (domain.includes('kukucard.com')) {
  initVueInstance(routerC);
} else {
  initVueInstance(router);
}